import {
  PayPalButtonsComponentOptions,
  OnInitActions,
  PayPalButtonsComponent,
} from "@paypal/paypal-js";
import { Uuid } from "../../../../application/core/shared/uuid/Uuid";
import { PayPalMockButtonClickHandler } from "./PayPalMockButtonClickHandler";

export class PayPalMockedButtonComponent {
  private orderId: string;

  constructor(orderId: string) {
    this.orderId = orderId;
  }

  // Oninit callback
  private onInitCallback(options: PayPalButtonsComponentOptions) {
    const srciTransactionId = Uuid.uuidv4();
    const clientOnInitActions: OnInitActions = {
      enable: () => Promise.resolve(),
      disable: () => Promise.resolve(),
    };
    clientOnInitActions
      .enable()
      .then(() => {
        options.onInit(
          { correlationID: srciTransactionId },
          clientOnInitActions,
        );
      })
      .catch((error) => {});
  }

  public mockButtons(
    options?: PayPalButtonsComponentOptions,
  ): PayPalButtonsComponent {
    const buttonClickHandler = new PayPalMockButtonClickHandler(this.orderId);

    return {
      close: () => Promise.resolve(),
      updateProps: (props: PayPalButtonsComponentOptions) => Promise.resolve(),
      isEligible: () => true,
      render: async (container: HTMLElement | string) => {
        const buttonElement = document.createElement("button");
        buttonElement.textContent = options?.fundingSource;
        buttonElement.style.marginBottom = "10px";
        buttonElement.setAttribute("id", options?.fundingSource);
        buttonElement.onclick = (event) =>
          buttonClickHandler.handleButtonClick(options, event);
        if (typeof container === "string") {
          document.querySelector(container)?.appendChild(buttonElement);
        } else {
          container.appendChild(buttonElement);
        }
        this.onInitCallback(options);
      },
    };
  }
}
