import { Service } from "typedi";
import { IApplePaySession } from "../../models/IApplePaySession";
import { IApplePayShippingMethodChangedEvent } from "../../models/IApplePayShippingMethodChangedEvent";
import { IApplePayPaymentDataCallBacks } from "../../../models/IApplePayPaymentDataCallbacks";
import { RequestJwtUpdateHandler } from "../../../../../application/core/services/st-codec/RequestJwtUpdateHandler";
import { ApplePayShippingMethodResolver } from "./ApplePayShippingMethodResolver";

/**
 * Represents the binding action for ApplePay's onshippingmethodselected callback.
 *
 * Here, we offer the ability for merchants to specify a way to resolve the callback
 * and/or update the JWT for the run.
 */
@Service()
export class ApplePayShippingMethodAction {
  constructor(private jwtRequestHandler: RequestJwtUpdateHandler) {}

  public execute(
    session: IApplePaySession,
    version: number,
    data: IApplePayShippingMethodChangedEvent,
    callbacks: IApplePayPaymentDataCallBacks | undefined,
  ): void {
    if (!callbacks || !callbacks.onShippingMethodSelected) {
      // No need to do anything if there is no callback specified by the merchant.
      return;
    }

    const actions = new ApplePayShippingMethodResolver(
      session,
      version,
      this.jwtRequestHandler,
    );

    callbacks.onShippingMethodSelected(data, actions);
  }
}
