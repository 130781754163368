import { IApplePayPaymentRequest } from "../models/IApplePayPaymentRequest";
import { IApplePayShippingLineItem } from "../models/IApplePayShippingLineItem";
import { IApplePayShippingMethod } from "../models/IApplePayShippingMethod";
import { ApplePayMockModal } from "./ApplePayMockModal";
import { MockedApplePaySession } from "./MockedApplePaySession";

export class ApplePayMockUiController {
  private modal: ApplePayMockModal;
  private paymentRequest: IApplePayPaymentRequest;

  constructor(paymentRequest: IApplePayPaymentRequest) {
    this.paymentRequest = paymentRequest;
  }

  public open(sessionMock: MockedApplePaySession): void {
    this.modal = new ApplePayMockModal(this.paymentRequest);
    this.modal.open();

    this.modal.setAuthorizeCallback(sessionMock.proceedPayment);
    this.modal.setCancelCallback(() => this.close(sessionMock));

    if (sessionMock.onshippingcontactselected) {
      this.modal.setShippingContactCallback(
        sessionMock.onshippingcontactselected,
      );
    }

    if (sessionMock.onshippingmethodselected) {
      this.modal.setShippingMethodSelectedCallback(
        sessionMock.onshippingmethodselected,
      );
    }
  }

  public updateShippingAmountChanges(
    status: unknown,
    newShippingMethods?: IApplePayShippingMethod[],
    newTotal?: IApplePayShippingLineItem,
    newLineItems?: IApplePayShippingLineItem[],
  ): void {
    this.modal.updateShippingAmountChanges(
      status,
      newShippingMethods,
      newTotal,
      newLineItems,
    );
  }

  public updateShippingMethodAmountChanges(
    status: unknown,
    newTotal?: unknown,
    newLineItems?: unknown,
  ) {
    this.modal.updateShippingMethodAmountChanges(
      status,
      newTotal,
      newLineItems,
    );
  }

  public unlockPaymentButton(): void {
    this.modal.unlock();
  }

  public close(applePaySession: MockedApplePaySession): void {
    applePaySession.oncancel(undefined);
    this.modal.close();
  }
}
