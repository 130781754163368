import { VISA_SANDBOX_JWK } from "../integrations/click-to-pay/digital-terminal/encrypt/keys/visa-sandbox";
import { VISA_PROD_JWK } from "../integrations/click-to-pay/digital-terminal/encrypt/keys/visa-prod";
import { MASTERCARD_SANDBOX_JWK } from "../integrations/click-to-pay/digital-terminal/encrypt/keys/mastercard-sandbox";
import { MASTERCARD_PROD_JWK } from "../integrations/click-to-pay/digital-terminal/encrypt/keys/mastercard-prod";

// FRAME_URL is declared externally.
// We use the `declare` keyword to inform TypeScript of its existence and type.
// Therefore, we disable the naming convention rule for this line to avoid linting errors,

declare const FRAME_URL: string | undefined;

const gatewayUrl = "https://webservices.securetrading.net/jwt/";
const mockGatewayUrl = "https://merchant.example.com:8443";

export const ENVIRONMENT = {
  CARDINAL_COMMERCE: {
    CONFIG: {
      logging: { level: "off" },
      timeout: 6000,
      extendedTimeout: 4000,
      maxRequestRetries: 2,
    },
    MOCK: {
      AUTHENTICATE_CARD_URL: `${mockGatewayUrl}/cardinalAuthenticateCard`,
    },
    SONGBIRD_LIVE_URL:
      "https://songbird.cardinalcommerce.com/edge/v1/songbird.js",
    SONGBIRD_TEST_URL:
      "https://songbirdstag.cardinalcommerce.com/edge/v1/songbird.js",
  },
  FRAME_URL: FRAME_URL || "https://webservices.securetrading.net/js/v3",
  GATEWAY_URL: gatewayUrl,
  NOTIFICATION_TTL: 7000,
  GOOGLE_PAY: {
    GOOGLE_PAY_URL: "https://pay.google.com/gp/p/js/pay.js",
  },
  production: true,
  testEnvironment: false,
  overrideDomain: "",
  THREEDS_TERM_URL:
    "https://payments.securetrading.net/process/payments/threedsmpilistener",
  BROWSER_DATA_URLS: [
    "https://brw.3ds.trustpayments.com/3dss/brw/browserData",
    "https://brw.3ds.trustpayments.com/3dss/brw/aragorn",
    "https://brw.3ds.trustpayments.com/3dss/brw/boromir",
  ],
  REQUEST_TIMEOUT: 30000,
  SENTRY: {
    DSN: "https://db623d7ce7ce4797b6af55b5afcecbe2@o402164.ingest.sentry.io/4504061517627392",
    ALLOWED_URLS: ["https://webservices.securetrading.net"],
    SAMPLE_RATE: 0.1,
  },
  SCRIPT_LOAD_TIMEOUT: 30000,
  CLICK_TO_PAY: {
    USE_STUBS: false,
    VISA: {
      SANDBOX: {
        SRC_SDK_URL:
          "https://sandbox-assets.secure.checkout.visa.com/checkout-widget/resources/js/src-i-adapter/visaSdk.js",
        SRC_INITIATOR_ID: "GSTIDU1J8I2NQRWAU7EL21puifGrG2BzgnL9XfBjMzwo9wmtM",
        ENCRYPTION_KID: "A5CHRN38V3PJ90ACENUH13CCVOyXIL7A8rC9xClvyZyxvMgrE",
        JWK: VISA_SANDBOX_JWK,
        DPA_ID: "TrustPayments-test-merchant",
      },
      PROD: {
        SRC_SDK_URL:
          "https://assets.secure.checkout.visa.com/checkout-widget/resources/js/src-i-adapter/visaSdk.js",
        SRC_INITIATOR_ID: "VV7E1DKJY1YSAEKRWX2Q21Pt6pDE8rMwMOoKOFaBZD0XFO9_E",
        ENCRYPTION_KID: "CTIFSY53E8M3JHRHWVAF14aMN2MCXYSiO0-ZrqdW_QDT9p9Mg",
        JWK: VISA_PROD_JWK,
        DPA_ID: "TrustPayments-test-merchant",
      },
    },
    MASTERCARD: {
      SANDBOX: {
        SRC_SDK_URL:
          "https://sandbox.src.mastercard.com/sdk/srcsdk.mastercard.js",
        SRC_INITIATOR_ID: "f621a412-9acc-4186-9c60-84f272090b60",
        ENCRYPTION_KID: "149123-src-fpan-encryption",
        JWK: MASTERCARD_SANDBOX_JWK,
      },
      PROD: {
        SRC_SDK_URL: "https://src.mastercard.com/sdk/srcsdk.mastercard.js",
        SRC_INITIATOR_ID: "4e4339c6-2ab1-43a5-a88d-9cf6976df667",
        ENCRYPTION_KID: "151094-src-fpan-encryption",
        JWK: MASTERCARD_PROD_JWK,
      },
    },
    SCRIPT_LOAD_TIMEOUT: 3000,
    REQUEST_TIMEOUT: 300,
  },
  PAYPAL: {
    DATA_PARTNER_ATTRIBUTION_ID: "TRUSTPAYMENTS_PSP",
    INTEGRATION_DATE: {
      SANDBOX: "2023-03-31",
      LIVE: "2023-03-31",
    },
    USE_GATEWAY_MOCK: false,
    USE_UI_MOCK: false,
    CLIENT_ID: {
      SANDBOX:
        "ASRoi1WzEK1JTvupdnvypddGrC63m4GCmy9AgXY4Z4wd8vVQ2NLS4e4cplEUyK6fZrSwihkPqtnJq4ci",
      LIVE: "AVq0873SWCW65G8UTVJn97fMe88ouErTYYZQ9BM24jKPewduS5qINOqzW-Vdk-hqwzNfYRUOBt6ygoYr",
    },
  },
  APPLEPAY: {
    USE_UI_MOCK: false,
    USE_GATEWAY_MOCK: false,
  },
};
