import { IApplePayPaymentRequest } from "../models/IApplePayPaymentRequest";
import { IApplePayShippingContactSelectedEvent } from "../models/IApplePayShippingContactSelectedEvent";
import { IApplePayShippingLineItem } from "../models/IApplePayShippingLineItem";
import { IApplePayShippingMethodChangedEvent } from "../models/IApplePayShippingMethodChangedEvent";
import { ApplePayMockShippingContact } from "./ApplePayMockShippingContact";
import { ApplePayMockShippingMethod } from "./ApplePayMockShippingMethod";
export class ApplePayMockModal {
  private overlay: HTMLElement;
  private modalContent: HTMLElement;
  private totalAmount: string = "0.00";
  private price: string = "0.00";
  private paymentRequest: IApplePayPaymentRequest;
  private shippingHandler: ApplePayMockShippingContact;
  private applePayMockShippingMethod: ApplePayMockShippingMethod;

  private onAuthorize: () => void = () => {};
  private onCancel: () => void = () => {};

  constructor(paymentRequest: IApplePayPaymentRequest) {
    this.paymentRequest = paymentRequest;
    this.applePayMockShippingMethod = new ApplePayMockShippingMethod(this);
    this.shippingHandler = new ApplePayMockShippingContact(this);

    this.overlay = document.createElement("div");
    this.modalContent = document.createElement("div");

    // Set IDs for easier CSS targeting and JS manipulation
    this.overlay.id = "applePayMockOverlay";
    this.modalContent.id = "applePayMockModal";

    this.setupOverlay();
    this.setupModal();
  }

  public setAuthorizeCallback(callback: () => void): void {
    this.onAuthorize = callback;
  }

  public setCancelCallback(callback: () => void): void {
    this.onCancel = callback;
  }

  public updateTotalAmount(totalAmount: string, price?: string): void {
    if (price) {
      this.price = price;
    } else {
      this.price = totalAmount; // Assuming price is the same as total for simplicity
    }
    this.totalAmount = totalAmount;
    if (this.modalContent) {
      this.updateContent();
    }
  }
  public setShippingMethodSelectedCallback(
    callback: (event: IApplePayShippingMethodChangedEvent) => void,
  ): void {
    this.applePayMockShippingMethod.setShippingMethodCallback(callback);
  }

  private updateContent(): void {
    const priceElement = this.modalContent.querySelector("#price");
    const totalElement = this.modalContent.querySelector("#total-amount");
    if (priceElement) {
      priceElement.textContent = `Price: £${this.price}`;
    }
    if (totalElement) {
      totalElement.textContent = `Total: £${this.totalAmount}`;
    }
  }

  private setupOverlay(): void {
    Object.assign(this.overlay.style, {
      position: "fixed",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: "1000",
    });
  }

  public setShippingContactCallback(
    callback: (event: IApplePayShippingContactSelectedEvent) => void,
  ): void {
    this.shippingHandler.setShippingContactCallback(callback);
    this.shippingHandler.initShippingContactUI(
      this.paymentRequest.shippingContact,
      this.modalContent,
    );
    this.shippingHandler.onShippingContactSelected();
  }

  private setupModal(): void {
    Object.assign(this.modalContent.style, {
      maxWidth: "400px",
      minHeight: "600px",
      backgroundColor: "#fff",
      borderRadius: "8px",
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
      padding: "20px",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      overflow: "auto",
    });
  }

  private invokeShippingMethod() {
    if (this.paymentRequest?.shippingMethods?.length > 0) {
      this.applePayMockShippingMethod.initShippingMethodUI(
        this.paymentRequest.shippingMethods,
        this.modalContent,
        this.price,
      );
    }
  }
  public open(): void {
    this.modalContent.innerHTML = this.createHtmlContent();
    this.overlay.appendChild(this.modalContent);
    document.body.appendChild(this.overlay);
    this.attachEventListeners();
    this.invokeShippingMethod();
    this.updateTotalAmount(this.paymentRequest.total.amount);
  }

  private attachEventListeners(): void {
    this.modalContent
      .querySelector("#cancel")
      ?.addEventListener("click", () => {
        this.close();
        this.onCancel();
      });

    this.modalContent
      .querySelector("#authorize")
      ?.addEventListener("click", () => {
        this.close();
        this.onAuthorize();
      });
  }
  public close(): void {
    this.overlay.remove();
  }

  public unlock(): void {
    const authorizeButton = this.modalContent.querySelector(
      "#authorize",
    ) as HTMLButtonElement;
    if (authorizeButton) authorizeButton.disabled = false;
  }

  public updateShippingAmountChanges(
    status: unknown,
    newShippingMethods?: unknown,
    newTotal?: IApplePayShippingLineItem,
    newLineItems?: IApplePayShippingLineItem[],
  ): void {
    const updatedShippingContactData = {
      status,
      newShippingMethods,
      newTotal,
      newLineItems,
    };

    this.shippingHandler.handleShippingContactUpdate(
      updatedShippingContactData,
      this.paymentRequest.total.amount,
    );
  }

  public updateShippingMethodAmountChanges(
    status: unknown,
    newTotal: unknown,
    newLineItems: unknown,
  ) {
    const updateShippingMethodData = {
      status,
      newTotal,
      newLineItems,
    };

    this.applePayMockShippingMethod.handleShippingMethodUpdate(
      updateShippingMethodData,
      this.paymentRequest.total.amount,
    );
  }

  private createHtmlContent(): string {
    return `
      <style>
        .title {
          font-size: 24px;
          margin-bottom: 20px;
        }
        .order-summary {
          text-align: left;
          margin-bottom: 20px;
        }
        .order-summary h2 {
          font-size: 20px;
          margin: 0 0 10px 0;
        }
        .order-summary p {
          margin: 5px 0;
        }
        button {
          color: white;
          border: none;
          border-radius: 4px;
          padding: 10px 20px;
          margin: 10px 0;
          cursor: pointer;
          font-size: 16px;
          width: calc(100% - 20px);
        }
        button:hover {
          opacity: 0.8;
        }
        #cancel {
          background-color: #6185ab;
        }
      
        #authorize {
          background-color: #007aff;
        }
        .error-message {
          color: #ff3b30;
          display: none;
        }
      </style>
      <div class="title">Payment</div>
      <div class="order-summary">
        <h2>Order Summary</h2>
        <p>Item: Fancy Widget</p>
        <p id="price">Price: £${this.price}</p>

         <div ><strong>Address:</strong><br></div>
          <p id="shipping-container"/>

        <div id="shipping-charges">Shipping Charges: Free</div>
        <p id="shippingmethod-container"></p>
        <p id="total-amount">Total: £${this.totalAmount}</p>
      </div>
      <button id="cancel">Cancel</button>
      <button id="authorize" disabled>Authorize</button>
    `;
  }
}
