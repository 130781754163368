import { IApplePayShippingMethod } from "../models/IApplePayShippingMethod";
import { IApplePayShippingMethodChangedEvent } from "../models/IApplePayShippingMethodChangedEvent";
import { ApplePayMockModal } from "./ApplePayMockModal";

export class ApplePayMockShippingMethod {
  private modal: ApplePayMockModal;
  private shippingMethods: IApplePayShippingMethod[] = [];
  private selectedMethod: IApplePayShippingMethod | null = null;
  private price: string = "";

  private onShippingMethodSelected: (
    event: IApplePayShippingMethodChangedEvent,
  ) => void = () => {};
  private shippingMethodComboBox: HTMLSelectElement | null = null;

  constructor(modal: ApplePayMockModal) {
    this.modal = modal;
  }

  public setShippingMethodCallback(
    callback: (event: IApplePayShippingMethodChangedEvent) => void,
  ) {
    this.onShippingMethodSelected = callback;
  }

  public initShippingMethodUI(
    shippingMethods: IApplePayShippingMethod[],
    modalContent: HTMLElement,
    price: string,
  ): void {
    this.shippingMethods = shippingMethods;
    this.price = price;
    const parentShippingMethodContainer = document.createElement("div");
    parentShippingMethodContainer.className = "shippingmethod-parent-container";
    parentShippingMethodContainer.style.marginBottom = "0px";

    const label = document.createElement("label");
    label.textContent = "Method: ";

    this.shippingMethodComboBox = document.createElement("select");
    this.updateTotalAmount();

    // Add shipping methods to the combo-box
    this.shippingMethods.forEach((shippingMethod, index) => {
      const option = document.createElement("option");
      option.value = index.toString();

      option.selected = shippingMethod?.selected ?? false;
      if (shippingMethod?.selected) {
        this.selectedMethod = shippingMethod;
      }

      option.style.fontWeight = "bold";
      option.textContent =
        "£" + shippingMethod.amount + " - " + shippingMethod.label;
      this.shippingMethodComboBox!.appendChild(option);

      const optionDetail = document.createElement("option");
      optionDetail.value = index.toString();
      optionDetail.textContent = shippingMethod.detail;
      optionDetail.disabled = true;
      this.shippingMethodComboBox!.appendChild(optionDetail);
    });

    // Append label and combo-box to the container
    parentShippingMethodContainer.appendChild(label);
    parentShippingMethodContainer.appendChild(this.shippingMethodComboBox);

    // Insert the shipping container after the "Item: Fancy Widget" text
    const shippingContainerPlaceholder = modalContent.querySelector(
      "#shippingmethod-container",
    );
    if (shippingContainerPlaceholder) {
      shippingContainerPlaceholder.appendChild(parentShippingMethodContainer);
    }

    // Add event listener for shipping method selection
    this.shippingMethodComboBox.addEventListener("change", () => {
      this.onShippingMethodChange();
    });
  }

  private onShippingMethodChange(): void {
    const selectedOptionIndex = this.shippingMethodComboBox?.value;
    this.selectedMethod =
      this.shippingMethods[parseInt(selectedOptionIndex || "0", 10)];
    const event = this.wrapEvent(this.selectedMethod);

    // Trigger the callback
    this.onShippingMethodSelected(event);
  }

  private wrapEvent(
    shippingMethodSelected: IApplePayShippingMethod,
  ): IApplePayShippingMethodChangedEvent {
    return {
      shippingMethod: shippingMethodSelected,
    };
  }

  public handleShippingMethodUpdate(
    updateShippingMethodData: any,
    price: string,
  ): void {
    const newAmount =
      updateShippingMethodData?.newTotal?.amount ||
      updateShippingMethodData?.status?.newTotal?.amount;

    if (newAmount) {
      this.updateNewAmountWithShippingAmount(newAmount, price);
    }
  }

  private updateNewAmountWithShippingAmount(newPrice: string, price: string) {
    const shippingAmount = parseFloat(this.selectedMethod?.amount || "0");
    const amount = shippingAmount + parseFloat(newPrice);
    this.modal.updateTotalAmount(amount.toString(), price);
  }

  private getSelectedShippingMethodOrDefault(): IApplePayShippingMethod {
    const selectedMethod = this.shippingMethods.find(
      (method) => method.selected,
    );
    return selectedMethod || this.shippingMethods[0];
  }

  private updateTotalAmount() {
    const defaultShipping = this.getSelectedShippingMethodOrDefault();
    if (parseFloat(defaultShipping.amount) > 0) {
      const totalAmount =
        parseFloat(defaultShipping.amount) + parseFloat(this.price);
      this.modal.updateTotalAmount(totalAmount.toString(), this.price);
    }
  }
}
