/* istanbul ignore file */
import { IApplePayPaymentAuthorizationResult } from "../models/IApplePayPaymentAuthorizationResult";
import { IApplePayPaymentAuthorizedEvent } from "../models/IApplePayPaymentAuthorizedEvent";
import { IApplePayPaymentRequest } from "../models/IApplePayPaymentRequest";
import { IApplePaySession } from "../models/IApplePaySession";
import { IApplePayShippingContactSelectedEvent } from "../models/IApplePayShippingContactSelectedEvent";
import { IApplePayShippingContactUpdate } from "../models/IApplePayShippingContactUpdate";
import { IApplePayShippingLineItem } from "../models/IApplePayShippingLineItem";
import { IApplePayShippingMethod } from "../models/IApplePayShippingMethod";
import { IApplePayShippingMethodChangedEvent } from "../models/IApplePayShippingMethodChangedEvent";
import { IApplePayShippingMethodUpdate } from "../models/IApplePayShippingMethodUpdate";
import { IApplePayValidateMerchantEvent } from "../models/IApplePayValidateMerchantEvent";
import { ApplePayMockUiController } from "./ApplePayMockUiController";

export class MockedApplePaySession implements IApplePaySession {
  private mockUserInterface: ApplePayMockUiController;
  private paymentRequest: IApplePayPaymentRequest;

  oncancel: (event: Event) => void;
  onpaymentauthorized: (event: IApplePayPaymentAuthorizedEvent) => void;
  onvalidatemerchant: (event: IApplePayValidateMerchantEvent) => void;
  onshippingmethodselected: (
    event: IApplePayShippingMethodChangedEvent,
  ) => void;

  constructor(paymentRequest: IApplePayPaymentRequest) {
    this.paymentRequest = paymentRequest;
    this.mockUserInterface = new ApplePayMockUiController(this.paymentRequest);
    this.proceedPayment = this.proceedPayment.bind(this);
  }

  completeShippingContactSelection(update: IApplePayShippingContactUpdate);
  completeShippingContactSelection(
    status: number,
    newShippingMethods: IApplePayShippingMethod[],
    newTotal: object,
    newLineItems: object[],
  ): void;
  completeShippingContactSelection(
    status: unknown,
    newShippingMethods?: unknown,
    newTotal?: unknown,
    newLineItems?: unknown,
  ): void {
    if (typeof status === "number") {
      // Handle Apple Pay versions 1 and 2
      this.mockUserInterface.updateShippingAmountChanges(
        status,
        newShippingMethods as IApplePayShippingMethod[],
        newTotal as IApplePayShippingLineItem,
        newLineItems as IApplePayShippingLineItem[],
      );
    } else if (status) {
      // Handle Apple Pay version 3+
      const data = status as IApplePayShippingContactUpdate;
      this.mockUserInterface.updateShippingAmountChanges(
        data.errors,
        data.newShippingMethods,
        data.newTotal,
        data.newLineItems,
      );
    }
  }

  onshippingcontactselected: (
    event: IApplePayShippingContactSelectedEvent,
  ) => void;

  completeShippingMethodSelection(update: IApplePayShippingMethodUpdate): void;
  completeShippingMethodSelection(
    status: number,
    newTotal: object,
    newLineItems: object[],
  ): void;
  completeShippingMethodSelection(
    status: unknown,
    newTotal?: unknown,
    newLineItems?: unknown,
  ): void {
    this.mockUserInterface.updateShippingMethodAmountChanges(
      status,
      newTotal as IApplePayShippingLineItem,
      newLineItems as IApplePayShippingLineItem[],
    );
  }

  public begin(): void {
    this.mockUserInterface.open(this);
    this.onvalidatemerchant({
      validationURL: "https://webservices-mock.securetrading.net:6443/jwt/",
    });
  }

  public abort(): void {
    this.mockUserInterface.close(this);
  }

  public proceedPayment() {
    const data = {
      payment: "mockapplepaydata",
      status: "SUCCESS",
    };
    if (data.status === "SUCCESS") {
      // @ts-ignore
      this.onpaymentauthorized(data);
    } else {
      // @ts-ignore
      this.oncancel(data);
    }
  }

  public completeMerchantValidation(merchantSession: unknown): void {
    this.mockUserInterface.unlockPaymentButton();
  }

  public completePayment(status: IApplePayPaymentAuthorizationResult): void {
    this.mockUserInterface.close(this);
  }
}
